
.theme-18-h2{
    font-family: 'Comfortaa', cursive;
    font-weight: 400;
    font-size: 35px;
    margin-bottom: 1.43em;
    color: #2F4356;

    @media (max-width: 1120px) {
        font-size: clamp(24px, 3.35vw, 30px);
    }
    span{
        color: var(--color-2);
    }
}
.theme-18-header{
    max-width: 1300px;
    padding: 0 24px 0;
    font-size: 16px;
    margin: 0 auto;

    @media (max-width: 1120px) {
        font-size: clamp(10px, 1.41vw, 18px);
    }
    @media (max-width: 767px){
        padding: 0;
        position: relative;
    }
}
.theme-18-header-text{
    width: 58%;
    padding: 16px 1.6em 16px 0;
    margin-right: auto;

    > .btn{
        font-size: 20px;
    }

    @media (max-width: 991px){

        > .btn{
            font-size: 16px;
        }
    }

    @media (max-width: 767px){
        width: 100%;
        text-align: center;
        z-index: 1;
        padding: 10vw 24px;
        color: #ffffff;

        > .btn{
            font-size: 20px;
        }
    }
    h1{
        font-weight: 400;
        margin-bottom: 0.55em;
        line-height: 1.1;
        font-size: 2.81em;
        color: #2F4356;

        @media (max-width: 767px){
            font-size: 24px;
            margin-bottom: 0.8em;
            color: #ffffff;
        }
        span{
            color: var(--color-2);
        }
    }
    > p{
        margin-bottom: 2.1em;
        line-height: 1.2;
        font-size: 1.13em;
        color: #545454;

        @media (max-width: 767px){
            font-size: 17px;
            color: #ffffff;
        }
    }
}
.theme-18-header-img{
    width: 39.85%;

    @media (max-width: 767px){
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        height: 92%;
        background-color: #000000;
    }
    > img{
        width: 100%;
        object-fit: cover;

        @media (max-width: 767px){
            height: 100%;
            opacity: .4;
        }
    }
}
.theme-18-header-bottom{
    margin-top: -36px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    z-index: 1;

    @media (max-width: 767px){
        margin-top: 0;
    }
}
.theme-18-header-bottom-item{
    background: #FFFFFF;
    box-shadow: 0 4px 4px #E2ECF5;
    padding: 12px 30px;

    &:first-child{
        border-radius: 50px 0 0 50px;
    }
    &:last-child{
        border-radius: 0 50px 50px 0;
    }

    @media (max-width: 767px){
        padding: 10px 27px;
    }

    span{
        color: #52677A;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;

        @media (max-width: 767px){
            font-size: 16px;
        }
    }
    p{
        color: #545454;
        font-size: 16px;
        margin-bottom: 0;

        @media (max-width: 767px){
            font-size: 14px;
        }
    }
}
.th-18-section-2{
    position: relative;
    padding: 110px 0 48px;

    @media (max-width: 767px){
        padding: 70px 0 24px;
    }
}
.th-18-section-2-ellipse{
    width: 602px;
    height: 623px;
    border-radius: 50%;
    transform: rotate(170deg);
    background: linear-gradient(99.71deg, rgba(245, 229, 232, 0.35) 21.16%, rgba(178, 200, 220, 0.35) 63.45%, rgba(255, 255, 255, 0.35) 63.45%);
    filter: blur(67.5px);
    position: absolute;
    left: 0;
    top: 0;

    @media (max-width: 767px){
        width: 50%;
        height: 50%;
    }
}
.th-18-section-2-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1599px) {
        max-width: 1092px;
        margin: 0 auto;
    }
}
.th-18-section-2-box-left{
    width: 45.78%;
    padding: 12px 0;

    @media (max-width: 767px){
        width: 100%;
    }
}
.th-18-section-2-box-right{
    width: 52%;
    max-width: 500px;
    padding: 12px 0 12px 12px;

    @media (max-width: 767px){
        width: 100%;
        padding-left: 0;
    }
}
.th-18-section-2-box-right-item{
    display: flex;
    align-items: flex-start;
    padding: 7px 0;

    > img{
        width: 64px;
        flex: 0 0 64px;
        margin-right: 8.2%;
        margin-top: 10px;

        @media (max-width: 991px) {
            width: 45px;
            flex: 0 0 45px;
            margin-right: 6%;
            margin-top: 6px;
        }
        @media (max-width: 767px){
            margin-right: 5%;
        }
    }
}
.th-18-section-2-box-right-text{
    flex-grow: 1;
    padding: 10px 0;

    @media (max-width: 991px){
        padding: 4px 0;
    }
    h3{
        margin-bottom: 13px;
        font-weight: 500;
        font-size: 22px;
        line-height: 27px;
        color: #52677A;

        @media (max-width: 991px){
            margin-bottom: 4px;
        }
        @media (max-width: 767px){
            font-size: 16px;
        }
    }
    p{
        line-height: 22px;
        margin-bottom: 0;
        color: #52677A;

        @media (min-width: 992px) {
            font-size: 18px;
        }
        @media (max-width: 767px){
            font-size: 14px;
        }
    }
}
.th-18-section-3{
    padding: 48px 0 48px;

    @media (max-width: 767px){
        padding: 36px 0;
    }
}
.th-18-section-3-content{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-left: -12px;
    margin-right: -12px;
    padding: 12px 0;
}
.th-18-section-3-item{
    text-align: center;
    padding: 12px;
    width: 33.33%;

    @media (max-width: 767px){
        width: 100%;
        padding: 15px 12px;
    }
    img{
        margin-bottom: 20px;
    }
    h3{
        margin-bottom: 12px;
        font-weight: 500;
        font-size: 22px;
        line-height: 27px;
        color: #52677A;

        @media (max-width: 1159px) {
            font-size: 20px;
            line-height: 24px;
        }
    }
    p{
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 0;
        color: #52677A;

        @media (max-width: 1159px) {
            font-size: 16px;
        }
    }
}
.th-18-section-4{
    padding: 48px 0 36px;

    @media (max-width: 767px){
        padding: 36px 0 24px;
    }
}
.th-18-section-4-item{
    border-bottom: 1px solid #B2C8DC;
    padding: 24px 0;
    display: flex;
    align-items: center;

    @media (max-width: 1159px) {
        padding: 18px 0;
    }
    @media (max-width: 575px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 0;
    }
    strong{
        color: #2F4356;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        width: 40%;
        padding-right: 20px;

        @media (max-width: 1159px) {
            font-size: 16px;
        }
        @media (max-width: 575px){
            width: 100%;
        }
    }
    p{
        color: #545454;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 0;
        width: 60%;

        @media (max-width: 1159px) {
            font-size: 16px;
        }
        @media (max-width: 575px){
            width: 100%;
            line-height: 20px;
            font-size: 14px;
        }
    }
}
.th-18-section-4-ellipse{
    width: 602px;
    height: 623px;
    border-radius: 50%;
    background: linear-gradient(90deg, rgba(245, 229, 232, 0.35) 21.16%, rgba(178, 200, 220, 0.35) 63.45%, rgba(255, 255, 255, 0.35) 63.45%);
    filter: blur(67.5px);
    position: absolute;
    right: 0;
    top: 0;
}
.th-18-section-5{
    padding: 48px 0 48px;

    @media (max-width: 767px){
        padding: 36px 0 24px;
    }
}
.th-18-section-6{
    padding: 48px 0 48px;

    @media (max-width: 767px){
        padding: 36px 0 24px;
    }
}
.th-18-section-6-container{
    display: flex;
    overflow: auto;
    gap: 20px;

    @media (hover) {
        &:hover{

            &::-webkit-scrollbar-thumb{
                background-color: #dde5eb;
            }
        }
        &::-webkit-scrollbar{
            height: 15px;
            opacity: 0;
            transition: opacity .3s;
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb:hover {
            background-color: #bdc9d2;
        }
    }

}
.th-18-section-6-item{
    width: 526px;
    flex: 0 0 526px;
    background: #F2F6F9;
    border-radius: 40px;
    margin: 0 1px 20px;
    color: #52677A;
    display: flex;
    flex-direction: column;
    padding: 40px 40px 25px;

    @media (max-width: 767px){
        padding: 30px 25px 20px;
        width: 340px;
        flex: 0 0 340px;
        margin: 0 0 20px;
    }
    > h3{
        font-weight: 500;
        font-size: 22px;
        line-height: 27px;
        margin-bottom: 13px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    > p{
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        margin-bottom: 30px;

        @media (max-width: 767px){
            margin-bottom: 30px;
        }
    }
}
.th-18-section-6-item-quot{
    margin-left: -10px;
    width: 60px;
    height: 60px;

    @media (max-width: 767px){
        width: 30px;
        height: 30px;
        margin-left: 0;
    }
}
.th-18-section-6-item-info{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: auto;

    @media (max-width: 767px){
        align-items: center;
    }
    figure{
        margin-bottom: 0;
        padding-left: 20px;
        display: flex;
        align-items: center;

        img{
            width: 48px;
            height: 48px;
            margin-right: 10px;
        }
    }
}
.th-18-section-7{
    padding: 60px 0 60px;
    background: linear-gradient(180deg, rgba(223, 232, 240, 0) 7.99%, #DFE8F0 100%);

    @media (max-width: 767px){
        padding: 48px 0;
    }
}
.th-18-section-8-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @media (max-width: 767px){
        flex-direction: column-reverse;
    }
}
.th-18-section-8-left{
    max-width: 563px;
    width: 48%;

    @media (max-width: 767px){
        width: 100%;
    }
    h2{
        font-weight: 400;
        font-size: 35px;
        color: #2F4356;
        margin-bottom: 20px;

        @media (max-width: 1120px){
            font-size: clamp(24px, 3.35vw, 30px);
        }
    }
    p{
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #545454;
        margin-bottom: 30px;

        @media (max-width: 767px){
            font-size: 16px;
        }
    }
    form{
        max-width: 500px;
    }
    .form-control{
        border: 1px solid #E0E9F1;
        border-radius: 20px;
        padding: 15px 28px;
        height: auto;

        @media (max-width: 767px){
            border-radius: 12px;
            padding: 12px 16px;
        }
    }
    textarea.form-control{
        min-height: 100px;
    }
}
.th-18-section-8-right{
    width: 48%;
    text-align: right;
    position: relative;

    @media (max-width: 767px){
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
        max-width: 276px;
    }
    img{
        max-width: 332px;
        width: 100%;

        @media (max-width: 767px){
            max-width: 206px;
        }
    }
}
.th-18-section-8-ellipse{
    background: #F5E5E8;
    filter: blur(26px);
    width: 411px;
    height: 411px;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    z-index: -1;
    max-width: 100%;

    @media (max-width: 767px){
        width: 298px;
        height: 298px;
        left: 0;
        margin: 0 auto;
    }
}
