@font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/inter-v12-latin-300.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/inter-v12-latin-regular.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/inter-v12-latin-500.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/inter-v12-latin-600.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/inter-v12-latin-700.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/inter-v12-latin-800.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/inter-v12-latin-900.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 300;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/comfortaa-v40-latin-300.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 400;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/comfortaa-v40-latin-regular.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 500;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/comfortaa-v40-latin-500.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 600;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/comfortaa-v40-latin-600.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 700;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/comfortaa-v40-latin-700.woff2') format('woff2');
}

@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 300;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-300.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 400;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-regular.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 500;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-500.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 600;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-600.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 700;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-700.woff2') format("woff2");
}

@import 'home';
@import '../../global/tenants-global';


:root {
    --nav-background: #ffffff;
    --nav-link: #545454;
    --nav-link-hover: #F8A2B2;

    --btn1-background-color: #F8A2B2;
    --btn1-background-hover-color: #B2C8DC;
    --btn1-text-color: #2F4356;
    --btn1-text-hover-color: #2F4356;

    --btn1-outline-background-color: #ffffff;
    --btn1-outline-background-hover-color: #F8A2B2;
    --btn1-outline-text-color: #2F4356;
    --btn1-outline-text-color-hover: #2F4356;
    --btn1-outline-border-color: #F8A2B2;
    --btn1-outline-border-hover-color: #F8A2B2;


    --btn2-background-color: #B2C8DC;
    --btn2-background-hover-color: #F8A2B2;
    --btn2-text-color: #ffffff;
    --btn2-text-hover-color: #2F4356;

    --btn2-outline-background-color: #ffffff;
    --btn2-outline-background-hover-color: #B2C8DC;
    --btn2-outline-text-color: #2F4356;
    --btn2-outline-text-color-hover: #ffffff;
    --btn2-outline-border-color: #B2C8DC;
    --btn2-outline-border-hover-color: #B2C8DC;


    --color-1: #52677A;
    --color-2: #F8A2B2;


    --course-background: #ffffff;
    --course-background-hover: #f8f8f8;
    --course-color: #52677A;
    --course-color-hover: #52677A;
    --course-color-hr: #e4e8f2;
    --course-color-hr-hover: #e4e8f2;
    --course-border: #849DB5;
    --course-radius: 20px;

}
h1,h2,h3,h4,h5,h6, p{
    min-height: 17px;
}

body{
    font-family: Inter, NotoSansArmenian, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

    .navbar{
        box-shadow: 3px 8px 11px rgba(121, 203, 208, 0.11);
    }
}

.font-comfortaa{
    font-family: 'Comfortaa', cursive;
}
h1{

    &:lang(en){
        font-family: 'Comfortaa', cursive;
    }
}
.btn{
    border-radius: 2em;
    font-size: 18px;
    padding: 0.6em 1.5em;
    letter-spacing: 0;

    @media (max-width: 991px){
        font-size: 16px;
    }
}



.home-course-item{
    box-shadow: none;
}
.home-course-item-block{

    hr{
        display: none;
    }
    h2{
        font-size: 20px;
        color: #2F4356;
        font-weight: 500;
        line-height: 1.2;
        margin-bottom: 16px;
        -webkit-line-clamp: 2;
    }
    p{
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        -webkit-line-clamp: 4;
    }
}
.home-course-info-block{
    padding: 11px 22px;
    height: 180px;
}

.home-course-author{
    padding: 14px 22px 8px;

    img{
        width: 48px;
        height: 48px;
        margin-right: 11px;
    }
    span{
        font-size: 16px;
        color: #2F4356;
    }
}
.home-course-price span{
    color: #849DB5;
}


.landing-bg{
    background: linear-gradient(180deg, #FFEAEA 0%, rgba(255, 234, 234, 0) 100%);
    max-width: none;
}
.landing-right, .landing-left{

    > h2, h1{
        font-weight: 400;
        font-size: 45px;
        line-height: 50px;
        color: #2F4356;
        margin-bottom: 40px;

        @media (max-width: 1120px){
            font-size: clamp(24px, 4vw, 45px);
            line-height: 1.3;
            margin-bottom: 1em;
        }
    }
    p {
        color: #545454;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 39px;

        &:last-child{
            margin-bottom: 0;
        }
        @media (max-width: 767px){
            font-size: 16px;
            margin-bottom: 20px;
        }
    }
}


.theme-all-info-section-2-container{

    h2{
        color: #2F4356;
        font-weight: 400;
        font-size: 45px;
        line-height: 50px;

        @media (max-width: 1120px){
            font-size: clamp(24px, 4vw, 45px);
            line-height: 1.3;
            margin-bottom: 1em;
        }
    }
    p{
        color: #545454;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 39px;

        &:last-child{
            margin-bottom: 0;
        }
        @media (max-width: 767px){
            font-size: 16px;
            margin-bottom: 20px;
        }
    }
}
